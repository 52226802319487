<i18n src="@/locales/pages/fhg-online.json"></i18n>

<template>
  <div>
    <PortfolioIntro id="intro">
      <template #title>
        {{ $t('portfolio.fhgOnline.intro.title') }}
      </template>
      <template #subtitle>
        {{ $t('portfolio.fhgOnline.intro.subtitle') }}
      </template>
      <template #description>
        {{ $t('portfolio.fhgOnline.intro.description') }}
      </template>

      <template #services>
        {{ $t('portfolio.fhgOnline.intro.details.services') }}
      </template>
      <template #tools>
        {{ $t('portfolio.fhgOnline.intro.details.tools') }}
      </template>
      <template #year>
        {{ $t('portfolio.fhgOnline.intro.details.year') }}
      </template>
      <template #code>
        <a target="_blank" href="https://github.com/dennis-adamczyk/fhg-online"
          >GitHub/dennis-adamczyk/fhg-online</a
        >
      </template>
      <template #preview>
        <a target="_blank" href="https://beta.fhg-online.de"
          >beta.fhg-online.de</a
        >
      </template>
    </PortfolioIntro>
    <section id="idea">
      <div class="outer">
        <div class="content">
          <div class="inner">
            <picture class="mockup">
              <source
                media="(max-width: 599px)"
                srcset="@/assets/portfolio/fhg-online/mobileMockup.webp"
                type="image/webp"
              />
              <source
                media="(max-width: 599px)"
                srcset="@/assets/portfolio/fhg-online/mobileMockup.png"
                type="image/png"
              />
              <source
                srcset="@/assets/portfolio/fhg-online/introMockup.webp"
                type="image/webp"
              />
              <source
                srcset="@/assets/portfolio/fhg-online/introMockup.png"
                type="image/png"
              />
              <img src="@/assets/portfolio/fhg-online/introMockup.png" alt="" />
            </picture>
            <div class="text">
              <p
                class="background-text rellax"
                data-rellax-speed="-1"
                data-rellax-percentage="0.5"
              >
                {{ $t('portfolio.fhgOnline.idea.title.short') }}
              </p>
              <h2 class="title">
                {{ $t('portfolio.fhgOnline.idea.title.long') }}
              </h2>
              <article
                v-for="(block, i) in $t('portfolio.fhgOnline.idea.blocks')"
                :key="i"
              >
                <h3>{{ block.heading }}</h3>
                <p v-for="(text, i) in block.text" :key="i">{{ text }}</p>
              </article>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="continuation">
      <div class="content">
        <p
          class="background-text rellax"
          data-rellax-speed="-1"
          data-rellax-percentage="0.5"
        >
          {{ $t('portfolio.fhgOnline.continuation.title.short') }}
        </p>
        <h2 class="title">
          {{ $t('portfolio.fhgOnline.continuation.title.long') }}
        </h2>
        <div class="inner">
          <article class="text">
            <h3>
              {{ $t('portfolio.fhgOnline.continuation.heading') }}
            </h3>
            <p>
              {{ $t('portfolio.fhgOnline.continuation.details') }}
            </p>
          </article>
          <div class="diagram">
            <img
              src="@/assets/portfolio/fhg-online/technicalDiagram.svg"
              alt=""
            />
            <p class="description">
              {{ $t('portfolio.fhgOnline.continuation.diagram.description') }}
            </p>
          </div>
        </div>
      </div>
    </section>
    <section id="concept">
      <div class="content">
        <p
          class="background-text rellax"
          data-rellax-speed="-1"
          data-rellax-percentage="0.5"
        >
          {{ $t('portfolio.fhgOnline.concept.title.short') }}
        </p>
        <h2 class="title">
          {{ $t('portfolio.fhgOnline.concept.title.long') }}
        </h2>
        <article>
          <p>
            {{ $t('portfolio.fhgOnline.concept.description') }}
          </p>
        </article>
        <article class="homework">
          <h3>
            {{ $t('portfolio.fhgOnline.concept.homework.heading') }}
          </h3>
          <div class="chart">
            <div class="start">
              <p>
                {{ $t('portfolio.fhgOnline.concept.homework.chart.start') }}
              </p>
            </div>
            <div class="arrow arrow1"></div>
            <div class="step step1">
              <p>
                {{ $t('portfolio.fhgOnline.concept.homework.chart.steps.0') }}
              </p>
            </div>
            <div class="arrow arrow2"></div>
            <div class="step step2">
              <p>
                {{ $t('portfolio.fhgOnline.concept.homework.chart.steps.1') }}
              </p>
            </div>
            <div class="arrow arrow3"></div>
            <div class="step question question1">
              <p>
                {{
                  $t(
                    'portfolio.fhgOnline.concept.homework.chart.steps.2.question'
                  )
                }}
              </p>
            </div>
            <div class="arrow yes yesArrow1">
              <img src="@/assets/portfolio/fhg-online/check-circle.svg" />
            </div>
            <div class="step step3">
              <p>
                {{
                  $t('portfolio.fhgOnline.concept.homework.chart.steps.2.yes.0')
                }}
              </p>
            </div>
            <div class="arrow arrow4"></div>
            <div class="step step4">
              <p>
                {{
                  $t('portfolio.fhgOnline.concept.homework.chart.steps.2.yes.1')
                }}
              </p>
            </div>
            <div class="arrow no noArrow">
              <img src="@/assets/portfolio/fhg-online/cross-circle.svg" />
            </div>
            <div class="step question question2">
              <p>
                {{
                  $t(
                    'portfolio.fhgOnline.concept.homework.chart.steps.2.no.0.question'
                  )
                }}
              </p>
            </div>
            <div class="arrow yes yesArrow2">
              <img src="@/assets/portfolio/fhg-online/check-circle.svg" />
            </div>
            <div class="step step5">
              <p>
                {{
                  $t(
                    'portfolio.fhgOnline.concept.homework.chart.steps.2.no.0.yes.0'
                  )
                }}
              </p>
            </div>
            <div class="arrow arrow5"></div>
            <div class="step step6">
              <p>
                {{
                  $t(
                    'portfolio.fhgOnline.concept.homework.chart.steps.2.no.0.yes.1'
                  )
                }}
              </p>
            </div>
          </div>
        </article>
        <article class="timetable">
          <h3>
            {{ $t('portfolio.fhgOnline.concept.timetable.heading') }}
          </h3>
          <div class="chart">
            <div class="start start1">
              <p>
                {{ $t('portfolio.fhgOnline.concept.timetable.chart.start.0') }}
              </p>
            </div>
            <div class="arrow arrow1"></div>
            <div class="step step1">
              <p>
                {{ $t('portfolio.fhgOnline.concept.timetable.chart.steps.0') }}
              </p>
            </div>
            <div class="arrow yes yesArrow1">
              <img src="@/assets/portfolio/fhg-online/check-circle.svg" />
            </div>
            <div class="start start2">
              <p>
                {{ $t('portfolio.fhgOnline.concept.timetable.chart.start.1') }}
              </p>
            </div>
            <div class="arrow arrow2"></div>
            <div class="step step2">
              <p>
                {{ $t('portfolio.fhgOnline.concept.timetable.chart.steps.1') }}
              </p>
            </div>
          </div>
        </article>
        <article class="devStates">
          <h3>
            {{ $t('portfolio.fhgOnline.concept.devStates.heading') }}
          </h3>
          <div class="boxes">
            <div
              class="box"
              v-for="(box, i) in $t(
                'portfolio.fhgOnline.concept.devStates.boxes'
              )"
              :key="i"
            >
              <div class="number">{{ i > 10 ? i : '0' + i }}</div>
              <div class="text">
                <p class="heading">{{ box.heading }}</p>
                <p class="details">{{ box.details }}</p>
              </div>
            </div>
          </div>
        </article>
      </div>
    </section>
    <section id="target">
      <div class="content">
        <p
          class="background-text rellax"
          data-rellax-speed="-1"
          data-rellax-percentage="0.5"
        >
          {{ $t('portfolio.fhgOnline.target.title.short') }}
        </p>
        <h2 class="title">
          {{ $t('portfolio.fhgOnline.target.title.long') }}
        </h2>
        <div class="groups">
          <article
            class="group"
            v-for="(group, i) in $t('portfolio.fhgOnline.target.groups')"
            :key="i"
          >
            <h3>
              {{ group.heading }}
            </h3>
            <p class="description" v-html="group.description"></p>
          </article>
        </div>
      </div>
    </section>
    <section id="design">
      <div class="content">
        <p
          class="background-text rellax"
          data-rellax-speed="-1"
          data-rellax-percentage="0.5"
        >
          {{ $t('portfolio.fhgOnline.design.title.short') }}
        </p>
        <h2 class="title">
          {{ $t('portfolio.fhgOnline.design.title.long') }}
        </h2>
        <article class="ui">
          <h3>
            {{ $t('portfolio.fhgOnline.design.ui.heading') }}
          </h3>
          <div class="material">
            <div class="text">
              <i class="icon-material-design icon"></i>
              <p class="heading">
                {{ $t('portfolio.fhgOnline.design.ui.material.heading') }}
              </p>
              <ul class="pros">
                <li
                  v-for="(pro, i) in $t(
                    'portfolio.fhgOnline.design.ui.material.pros'
                  )"
                  :key="i"
                >
                  {{ pro }}
                </li>
              </ul>
            </div>
            <div class="components">
              <div class="inner">
                <picture>
                  <source
                    srcset="@/assets/portfolio/fhg-online/sc_fhgOnline_home.webp"
                    type="image/webp"
                  />
                  <source
                    srcset="@/assets/portfolio/fhg-online/sc_fhgOnline_home.jpg"
                    type="image/jpg"
                  />
                  <img
                    src="@/assets/portfolio/fhg-online/sc_fhgOnline_home.jpg"
                  />
                </picture>
                <PortfolioMarker
                  v-for="(component, i) in $t(
                    'portfolio.fhgOnline.design.ui.material.components.left'
                  )"
                  :class="i"
                  :key="i"
                  :hideDot="i == 'badge'"
                  >{{ component }}</PortfolioMarker
                >
                <PortfolioMarker
                  v-for="(component, i) in $t(
                    'portfolio.fhgOnline.design.ui.material.components.right'
                  )"
                  align="right"
                  :class="i"
                  :key="i"
                  >{{ component }}</PortfolioMarker
                >
              </div>
            </div>
          </div>
          <div class="responsive">
            <div class="text">
              <i class="icon-responsive icon"></i>
              <p class="heading">
                {{ $t('portfolio.fhgOnline.design.ui.responsive.heading') }}
              </p>
              <ul class="pros">
                <li
                  v-for="(pro, i) in $t(
                    'portfolio.fhgOnline.design.ui.responsive.pros'
                  )"
                  :key="i"
                >
                  {{ pro }}
                </li>
              </ul>
            </div>
            <picture class="image">
              <source
                srcset="@/assets/portfolio/fhg-online/responsive.webp"
                type="image/webp"
              />
              <source
                srcset="@/assets/portfolio/fhg-online/responsive.png"
                type="image/png"
              />
              <img src="@/assets/portfolio/fhg-online/responsive.png" />
            </picture>
          </div>
        </article>
        <article class="logo">
          <h3>
            {{ $t('portfolio.fhgOnline.design.logo.heading') }}
          </h3>
          <div class="inner">
            <img
              src="@/assets/portfolio/fhg-online/logo.svg"
              alt="Logo"
              class="image"
            />
            <p
              class="text"
              v-html="$t('portfolio.fhgOnline.design.logo.description')"
            ></p>
          </div>
        </article>
        <article class="colors">
          <h3>
            {{ $t('portfolio.fhgOnline.design.colors.heading') }}
          </h3>
          <div class="inner">
            <div class="primary">
              <p class="heading">
                {{ $t('portfolio.fhgOnline.design.colors.primary.heading') }}
              </p>
              <div class="palette">
                <div
                  class="swatch"
                  v-for="(hex, name) in $t(
                    'portfolio.fhgOnline.design.colors.primary.palette'
                  )"
                  :key="name"
                  :style="{ backgroundColor: hex }"
                >
                  <p class="name" :style="{ color: contrastColor(hex) }">
                    {{
                      name == 50
                        ? $t('portfolio.fhgOnline.design.colors.primary.name') +
                          name
                        : name
                    }}
                  </p>
                  <p class="hex" :style="{ color: contrastColor(hex) }">
                    {{ hex }}
                  </p>
                  <PortfolioMarker
                    align="right"
                    inline
                    class="marker"
                    v-if="name == 200"
                  >
                    {{ $t('portfolio.fhgOnline.design.colors.primary.dark') }}
                  </PortfolioMarker>
                  <PortfolioMarker
                    align="right"
                    inline
                    class="marker"
                    v-if="name == 500"
                  >
                    {{ $t('portfolio.fhgOnline.design.colors.primary.light') }}
                  </PortfolioMarker>
                </div>
              </div>
            </div>
            <div class="material">
              <p class="heading">
                {{ $t('portfolio.fhgOnline.design.colors.material.heading') }}
              </p>
              <div class="palette">
                <div
                  class="swatch"
                  v-for="(swatch, i) in $t(
                    'portfolio.fhgOnline.design.colors.material.palette'
                  )"
                  :key="i"
                  :style="{ backgroundColor: swatch.color }"
                >
                  <p
                    class="name"
                    :style="{ color: contrastColor(swatch.color) }"
                  >
                    {{ swatch.name }}
                  </p>
                </div>
              </div>
              <p class="caption">
                {{ $t('portfolio.fhgOnline.design.colors.material.caption') }}
              </p>
            </div>
          </div>
        </article>
        <article class="typography">
          <h3>
            {{ $t('portfolio.fhgOnline.design.typography.heading') }}
          </h3>
          <div class="inner">
            <p class="description">
              {{ $t('portfolio.fhgOnline.design.typography.description') }}
            </p>
            <div class="fonts">
              <PortfolioFont family="Roboto" />
              <PortfolioFont family="Montserrat" />
            </div>
          </div>
        </article>
      </div>
    </section>
    <section id="result">
      <a target="_blank" href="https://beta.fhg-online.de" class="visitSite">
        {{ $t('portfolio.fhgOnline.result.visitSite') }}
        <i class="icon-open-in-new"></i>
      </a>
      <div class="content">
        <p
          class="background-text rellax"
          data-rellax-speed="-1"
          data-rellax-percentage="0.5"
        >
          {{ $t('portfolio.fhgOnline.result.title.short') }}
        </p>
        <h2 class="title">
          {{ $t('portfolio.fhgOnline.result.title.long') }}
        </h2>
        <article class="fhg9a">
          <h3 class="heading">
            {{ $t('portfolio.fhgOnline.result.fhg9a.heading') }}
            <span class="year">
              {{ $t('portfolio.fhgOnline.result.fhg9a.year') }}
            </span>
          </h3>
          <div class="gallery">
            <picture class="scDesktopHome">
              <source
                srcset="@/assets/portfolio/fhg-online/sc_fhg9a_desktop_home.webp"
                type="image/webp"
              />
              <source
                srcset="@/assets/portfolio/fhg-online/sc_fhg9a_desktop_home.jpg"
                type="image/jpg"
              />
              <img
                src="@/assets/portfolio/fhg-online/sc_fhg9a_desktop_home.jpg"
                alt="Screenshot"
              />
            </picture>
            <picture class="scMobileHome">
              <source
                srcset="@/assets/portfolio/fhg-online/sc_fhg9a_mobile_home.webp"
                type="image/webp"
              />
              <source
                srcset="@/assets/portfolio/fhg-online/sc_fhg9a_mobile_home.jpg"
                type="image/jpg"
              />
              <img
                src="@/assets/portfolio/fhg-online/sc_fhg9a_mobile_home.jpg"
                alt="Screenshot"
              />
            </picture>
            <picture class="scHomework">
              <source
                srcset="@/assets/portfolio/fhg-online/sc_fhg9a_homework.webp"
                type="image/webp"
              />
              <source
                srcset="@/assets/portfolio/fhg-online/sc_fhg9a_homework.jpg"
                type="image/jpg"
              />
              <img
                src="@/assets/portfolio/fhg-online/sc_fhg9a_homework.jpg"
                alt="Screenshot"
              />
            </picture>
            <picture class="scTimetable">
              <source
                srcset="@/assets/portfolio/fhg-online/sc_fhg9a_timetable.webp"
                type="image/webp"
              />
              <source
                srcset="@/assets/portfolio/fhg-online/sc_fhg9a_timetable.jpg"
                type="image/jpg"
              />
              <img
                src="@/assets/portfolio/fhg-online/sc_fhg9a_timetable.jpg"
                alt="Screenshot"
              />
            </picture>
            <picture class="scCalendar">
              <source
                srcset="@/assets/portfolio/fhg-online/sc_fhg9a_calendar.webp"
                type="image/webp"
              />
              <source
                srcset="@/assets/portfolio/fhg-online/sc_fhg9a_calendar.jpg"
                type="image/jpg"
              />
              <img
                src="@/assets/portfolio/fhg-online/sc_fhg9a_calendar.jpg"
                alt="Screenshot"
              />
            </picture>
            <picture class="scSettings">
              <source
                srcset="@/assets/portfolio/fhg-online/sc_fhg9a_settings.webp"
                type="image/webp"
              />
              <source
                srcset="@/assets/portfolio/fhg-online/sc_fhg9a_settings.jpg"
                type="image/jpg"
              />
              <img
                src="@/assets/portfolio/fhg-online/sc_fhg9a_settings.jpg"
                alt="Screenshot"
              />
            </picture>
          </div>
        </article>
        <article class="fhgOnline">
          <h3 class="heading">
            {{ $t('portfolio.fhgOnline.result.fhgOnline.heading') }}
            <span class="year">
              {{ $t('portfolio.fhgOnline.result.fhgOnline.year') }}
            </span>
          </h3>
          <div class="gallery">
            <div class="registration">
              <p class="heading">
                {{
                  $t(
                    'portfolio.fhgOnline.result.fhgOnline.registration.heading'
                  )
                }}
              </p>
              <p>
                {{
                  $t(
                    'portfolio.fhgOnline.result.fhgOnline.registration.details'
                  )
                }}
              </p>
            </div>
            <div class="home">
              <p class="heading">
                {{ $t('portfolio.fhgOnline.result.fhgOnline.home.heading') }}
              </p>
              <p>
                {{ $t('portfolio.fhgOnline.result.fhgOnline.home.details') }}
              </p>
            </div>
            <picture class="scWelcome">
              <source
                srcset="@/assets/portfolio/fhg-online/sc_fhgOnline_welcome.webp"
                type="image/webp"
              />
              <source
                srcset="@/assets/portfolio/fhg-online/sc_fhgOnline_welcome.jpg"
                type="image/jpg"
              />
              <img
                src="@/assets/portfolio/fhg-online/sc_fhgOnline_welcome.jpg"
                alt="Screenshot"
              />
            </picture>
            <picture class="scLogin">
              <source
                srcset="@/assets/portfolio/fhg-online/sc_fhgOnline_login.webp"
                type="image/webp"
              />
              <source
                srcset="@/assets/portfolio/fhg-online/sc_fhgOnline_login.jpg"
                type="image/jpg"
              />
              <img
                src="@/assets/portfolio/fhg-online/sc_fhgOnline_login.jpg"
                alt="Screenshot"
              />
            </picture>
            <picture class="scRegistration">
              <source
                srcset="@/assets/portfolio/fhg-online/sc_fhgOnline_registration.webp"
                type="image/webp"
              />
              <source
                srcset="@/assets/portfolio/fhg-online/sc_fhgOnline_registration.jpg"
                type="image/jpg"
              />
              <img
                src="@/assets/portfolio/fhg-online/sc_fhgOnline_registration.jpg"
                alt="Screenshot"
              />
            </picture>
            <picture class="scHome">
              <source
                srcset="@/assets/portfolio/fhg-online/sc_fhgOnline_home.webp"
                type="image/webp"
              />
              <source
                srcset="@/assets/portfolio/fhg-online/sc_fhgOnline_home.jpg"
                type="image/jpg"
              />
              <img
                src="@/assets/portfolio/fhg-online/sc_fhgOnline_home.jpg"
                alt="Screenshot"
              />
            </picture>
            <picture class="scHomework">
              <source
                srcset="@/assets/portfolio/fhg-online/sc_fhgOnline_homework.webp"
                type="image/webp"
              />
              <source
                srcset="@/assets/portfolio/fhg-online/sc_fhgOnline_homework.jpg"
                type="image/jpg"
              />
              <img
                src="@/assets/portfolio/fhg-online/sc_fhgOnline_homework.jpg"
                alt="Screenshot"
              />
            </picture>
            <picture class="scTimetable">
              <source
                srcset="@/assets/portfolio/fhg-online/sc_fhgOnline_timetable.webp"
                type="image/webp"
              />
              <source
                srcset="@/assets/portfolio/fhg-online/sc_fhgOnline_timetable.jpg"
                type="image/jpg"
              />
              <img
                src="@/assets/portfolio/fhg-online/sc_fhgOnline_timetable.jpg"
                alt="Screenshot"
              />
            </picture>
            <picture class="scHelp">
              <source
                srcset="@/assets/portfolio/fhg-online/sc_fhgOnline_help.webp"
                type="image/webp"
              />
              <source
                srcset="@/assets/portfolio/fhg-online/sc_fhgOnline_help.jpg"
                type="image/jpg"
              />
              <img
                src="@/assets/portfolio/fhg-online/sc_fhgOnline_help.jpg"
                alt="Screenshot"
              />
            </picture>
            <picture class="scSettings">
              <source
                srcset="@/assets/portfolio/fhg-online/sc_fhgOnline_settings.webp"
                type="image/webp"
              />
              <source
                srcset="@/assets/portfolio/fhg-online/sc_fhgOnline_settings.jpg"
                type="image/jpg"
              />
              <img
                src="@/assets/portfolio/fhg-online/sc_fhgOnline_settings.jpg"
                alt="Screenshot"
              />
            </picture>
            <picture class="scDesktopHome">
              <source
                srcset="@/assets/portfolio/fhg-online/sc_fhgOnline_desktop_home.webp"
                type="image/webp"
              />
              <source
                srcset="@/assets/portfolio/fhg-online/sc_fhgOnline_desktop_home.jpg"
                type="image/jpg"
              />
              <img
                src="@/assets/portfolio/fhg-online/sc_fhgOnline_desktop_home.jpg"
                alt="Screenshot"
              />
            </picture>
            <picture class="scDesktopHomework">
              <source
                srcset="@/assets/portfolio/fhg-online/sc_fhgOnline_desktop_homework.webp"
                type="image/webp"
              />
              <source
                srcset="@/assets/portfolio/fhg-online/sc_fhgOnline_desktop_homework.jpg"
                type="image/jpg"
              />
              <img
                src="@/assets/portfolio/fhg-online/sc_fhgOnline_desktop_homework.jpg"
                alt="Screenshot"
              />
            </picture>
          </div>
        </article>
      </div>
    </section>
  </div>
</template>

<script>
import PortfolioIntro from './layout/PortfolioIntro';
import PortfolioMarker from './layout/PortfolioMarker';
import PortfolioFont from './layout/PortfolioFont';

import Rellax from 'rellax';

export default {
  name: 'FhgOnline',
  components: {
    PortfolioIntro,
    PortfolioMarker,
    PortfolioFont,
  },
  metaInfo() {
    return {
      title: 'FHG Online ◃ Portfolio ◂ Dennis Adamczyk',
      meta: [
        {
          name: 'description',
          content:
            'FHG Online – Portfolio – Dennis Adamczyk • Web Developer & UI/UX Designer – School planner for Franz-Haniel-Gymnasium',
        },
        {
          name: 'keywords',
          content:
            'dennis adamczyk, fhg online, fhg-online, fhg, schulplaner, app, portfolio, projekt',
        },
      ],
    };
  },
  mounted() {
    new Rellax('.rellax', {
      breakpoints: [600, 768, 1201],
    });
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      let visitSiteBtn = document.querySelector('#result .visitSite');
      let resultSection = document.getElementById('result');
      let footer = document.querySelector('#app > footer');

      if (
        window.scrollY >
        document.documentElement.scrollHeight - resultSection.offsetHeight
      ) {
        if (!visitSiteBtn.classList.contains('show'))
          visitSiteBtn.classList.add('show');

        let updateBottom = () => {
          if (
            window.scrollY >=
            document.documentElement.scrollHeight -
              window.innerHeight -
              footer.offsetHeight
          ) {
            let newBottom =
              window.scrollY +
              window.innerHeight -
              (document.documentElement.scrollHeight - footer.offsetHeight);
            visitSiteBtn.style.setProperty(
              'transition',
              'background 120ms ease, bottom 0ms ease, opacity 120ms ease'
            );
            visitSiteBtn.style.setProperty(
              'bottom',
              (newBottom || 0) + 24 + 'px'
            );
          } else {
            visitSiteBtn.removeAttribute('style');
          }
        };
        updateBottom();
        setTimeout(updateBottom, 20);
      } else {
        if (visitSiteBtn.classList.contains('show'))
          visitSiteBtn.classList.remove('show');
      }
    },
    contrastColor(hex) {
      if (hex.indexOf('#') === 0) hex = hex.slice(1);

      if (hex.length === 3)
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];

      if (hex.length !== 6) throw new Error('Invalid HEX color.');

      let r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);

      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
    },
  },
};
</script>

<style lang="scss" src="./layout/projects.scss" scoped></style>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700;900&display=swap');

#intro {
  position: relative;
  z-index: 1;
}

#idea {
  background-color: $background-light;
  overflow: hidden;

  @media all and ($mobile) {
    background-color: none;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: -128px;
  }

  .outer {
    background-color: $background-light;

    @media all and ($mobile) {
      border-top: 128px solid $background;
      padding-bottom: 96px;
    }

    .content {
      @media all and ($mobile) {
        margin-top: -128px;
      }

      .inner {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;

        @media all and ($mobile) {
          flex-direction: column;
          justify-content: flex-start;
          align-items: stretch;
        }

        .mockup {
          margin-left: -48px;
          margin-right: -48px;
          margin-bottom: -96px;
          width: 448px;
          max-width: 45%;
          position: relative;
          z-index: 1;

          @media all and ($mobile) {
            margin-left: -24px;
            margin-right: 0;
            margin-bottom: 0;
            width: calc(100% + 2 * 24px);
            max-width: none;
            display: flex;
            justify-content: center;
            align-items: center;
            position: initial;
            z-index: 0;
          }

          img {
            width: 100%;
            height: auto;

            @media all and ($mobile) {
              width: 150%;
            }
          }
        }

        .text {
          width: 50%;
          position: relative;

          @media all and ($mobile) {
            margin-top: 256px;
            width: 100%;
            margin-top: auto;
            margin-left: 0;
          }

          article {
            &:last-of-type {
              margin-bottom: 0;

              p:last-child {
                margin-bottom: 0;
              }
            }
          }

          .background-text {
            top: -144px;
            left: 100%;

            @media all and ($mobile) {
              left: -38px;
              top: -144px;
            }
          }
        }
      }
    }
  }
}

#continuation {
  .content {
    .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      z-index: 1;

      @media all and ($mobile) {
        flex-direction: column;
        justify-content: flex-start;
      }

      .text {
        width: 50%;

        @media all and ($mobile) {
          width: 100%;
        }
      }

      .diagram {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        max-width: 40%;

        @media all and ($mobile) {
          max-width: 100%;
          width: 100%;
        }

        img {
          width: 100%;
        }

        p {
          font-size: 0.75rem;
          margin-top: 24px;
          text-align: center;
          max-width: 100%;
        }
      }
    }
  }
}

#concept {
  background-color: $background-light;

  .content {
    .homework {
      .chart {
        display: grid;
        grid-template-columns: 1fr 64px 1fr 64px 1fr;
        grid-template-rows: repeat(5, auto);
        grid-template-areas:
          'start arrow1 step1 arrow2 step2'
          '. . . . arrow3'
          'step4 arrow4 step3 yesArrow1 question1'
          '. . . . noArrow'
          'step6 arrow5 step5 yesArrow2 question2';
        gap: 0;
        justify-items: center;
        align-items: center;
        width: 100%;
        max-width: 100%;

        @media all and (max-width: 750px) {
          display: grid;
          grid-template-columns: 50% 50%;
          grid-template-rows: repeat(13, auto);
          grid-template-areas:
            'start start'
            'arrow1 arrow1'
            'step1 step1'
            'arrow2 arrow2'
            'step2 step2'
            'arrow3 arrow3'
            'question1 question1'
            'yesArrow1 noArrow'
            'step3 question2'
            'arrow4 yesArrow2'
            'step4 step5'
            '. arrow5'
            '. step6';
          gap: 0;
          justify-items: center;
          align-items: center;
          width: 100%;
          max-width: 100%;
        }

        .start {
          grid-area: start;
        }

        .arrow {
          &1 {
            grid-area: arrow1;
          }

          &2 {
            grid-area: arrow2;
          }

          &3 {
            grid-area: arrow3;

            min-width: auto;
            min-height: 64px;
            height: auto;
            width: 5px;
            border-right: none;
            border-bottom: 5px solid $background-light;

            &::after {
              width: 20px;
              height: 22px;
              top: auto;
              bottom: -5px;
              left: 50%;
              transform: translateX(-50%);
              border-bottom: none;
              border-right: 10px solid $background-light;
              border-left: 10px solid $background-light;
              border-top: 22px solid $background;
            }
          }

          &4 {
            grid-area: arrow4;

            transform: rotate(180deg);

            @media all and (max-width: 750px) {
              transform: none;
              margin-top: -30px;
              height: 110px;
            }
          }

          &5 {
            grid-area: arrow5;

            transform: rotate(180deg);

            @media all and (max-width: 750px) {
              transform: none;
            }
          }

          &.yes {
            &.yesArrow1 {
              grid-area: yesArrow1;

              min-width: 110px;
              margin-right: -42px;

              border-right: none;
              border-left: 5px solid $background-light;

              &::after {
                right: auto;
                left: -5px;
                border-right: 22px solid $background;
                border-left: none;
              }

              img {
                left: calc(50% + 10px);

                @media all and (max-width: 950px) {
                  left: calc(50% - 15px);
                }
              }

              @media all and (max-width: 750px) {
                min-width: auto;
                border-left: none;
                border-bottom: 5px solid $background-light;
                margin-right: calc(-100% + 90px);
                min-height: 120px;
                margin-top: -46px;
                margin-bottom: -30px;

                &::after {
                  width: 20px;
                  height: 22px;
                  left: 50%;
                  bottom: -5px;
                  top: auto;
                  transform: translateX(-50%);
                  border-right: 10px solid $background-light;
                  border-left: 10px solid $background-light;
                  border-top: 22px solid $background;
                  border-bottom: none;
                }

                img {
                  left: 50%;
                }
              }
            }

            &.yesArrow2 {
              grid-area: yesArrow2;

              min-width: 110px;
              margin-right: -42px;

              border-right: none;
              border-left: 5px solid $background-light;

              &::after {
                right: auto;
                left: -5px;
                border-right: 22px solid $background;
                border-left: none;
              }

              img {
                left: calc(50% + 10px);

                @media all and (max-width: 950px) {
                  left: calc(50% - 15px);
                }
              }

              @media all and (max-width: 750px) {
                min-width: auto;
                border-left: none;
                border-bottom: 5px solid $background-light;
                margin-right: -8px;
                min-height: 90px;
                margin-top: -8px;
                margin-bottom: 0;

                &::after {
                  width: 20px;
                  height: 22px;
                  left: 50%;
                  bottom: -5px;
                  top: auto;
                  transform: translateX(-50%);
                  border-right: 10px solid $background-light;
                  border-left: 10px solid $background-light;
                  border-top: 22px solid $background;
                  border-bottom: none;
                }

                img {
                  left: 50%;
                }
              }
            }
          }

          &.no {
            grid-area: noArrow;

            min-width: auto;
            min-height: 80px;
            height: auto;
            width: 5px;
            border-right: none;
            border-bottom: 5px solid $background-light;

            &::after {
              width: 20px;
              height: 22px;
              top: auto;
              bottom: -5px;
              left: 50%;
              transform: translateX(-50%);
              border-bottom: none;
              border-right: 10px solid $background-light;
              border-left: 10px solid $background-light;
              border-top: 22px solid $background;
            }

            img {
              top: 40%;
            }

            @media all and (max-width: 750px) {
              min-width: auto;
              border-left: none;
              border-bottom: 5px solid $background-light;
              margin-left: calc(-100% + 90px);
              min-height: 125px;
              margin-top: -46px;
              margin-bottom: -42px;

              &::after {
                width: 20px;
                height: 22px;
                left: 50%;
                bottom: -5px;
                top: auto;
                transform: translateX(-50%);
                border-right: 10px solid $background-light;
                border-left: 10px solid $background-light;
                border-top: 22px solid $background;
                border-bottom: none;
              }

              img {
                left: 50%;
              }
            }
          }
        }

        .step {
          &1 {
            grid-area: step1;
          }
          &2 {
            grid-area: step2;
          }
          &3 {
            grid-area: step3;

            @media all and (max-width: 750px) {
              margin-right: 8px;
            }
          }
          &4 {
            grid-area: step4;

            @media all and (max-width: 750px) {
              margin-right: 8px;
            }
          }
          &5 {
            grid-area: step5;

            @media all and (max-width: 750px) {
              margin-left: 8px;
              max-width: 100%;
              box-sizing: border-box;
            }
          }
          &6 {
            grid-area: step6;

            @media all and (max-width: 750px) {
              margin-left: 8px;
            }
          }
        }

        .question {
          &1 {
            grid-area: question1;
          }
          &.question2 {
            grid-area: question2;

            @media all and (max-width: 750px) {
              margin-left: 8px;
              width: 26vw;
              height: 26vw;
            }
          }
        }
      }
    }

    .timetable {
      .chart {
        display: grid;
        grid-template-columns: 1fr 64px 1fr 96px 1fr;
        grid-template-rows: repeat(3, auto);
        grid-template-areas:
          '. . . . start2'
          '. . . . arrow2'
          'start1 arrow1 step1 yesArrow1 step2';
        gap: 0;
        justify-items: center;
        align-items: center;
        width: 100%;
        max-width: 100%;

        @media all and (max-width: 750px) {
          grid-template-columns: 1fr 96px 1fr;
          grid-template-areas:
            'start1 . start2'
            'arrow1 . arrow2'
            'step1 yesArrow1 step2';
          align-items: stretch;
        }

        @media all and ($mobile) {
          grid-template-columns: 1fr 64px 1fr;
        }

        .start {
          display: flex;
          justify-content: center;
          align-items: center;

          &1 {
            grid-area: start1;
          }
          &2 {
            grid-area: start2;
          }

          @media all and ($mobile) {
            p {
              font-size: 0.8rem;
              line-height: normal;
            }
          }
        }

        .arrow {
          &1 {
            grid-area: arrow1;
          }
          &2 {
            grid-area: arrow2;

            min-width: auto;
            min-height: 64px;
            height: auto;
            width: 5px;
            border-right: none;
            border-bottom: 5px solid $background-light;

            &::after {
              width: 20px;
              height: 22px;
              right: auto;
              bottom: -5px;
              top: auto;
              left: 50%;
              transform: translateX(-50%);
              border-bottom: none;
              border-left: 10px solid $background-light;
              border-right: 10px solid $background-light;
              border-top: 22px solid $background;
            }
          }

          &.yes {
            grid-area: yesArrow1;

            width: 96px;

            @media all and (max-width: 750px) {
              min-height: auto;
              min-width: 96px;
              height: 5px;
              border-right: 5px solid $background-light;
              border-bottom: none;
              position: relative;
              top: 50%;
              transform: translateY(-50%);
              z-index: 0;
              box-sizing: border-box;

              &::after {
                content: '';
                display: block;
                width: 22px;
                height: 20px;
                position: absolute;
                right: -5px;
                top: 50%;
                left: auto;
                transform: translateY(-50%);
                border-top: 10px solid $background-light;
                border-bottom: 10px solid $background-light;
                border-left: 22px solid $background;
                border-right: none;
                box-sizing: border-box;
              }
            }

            @media all and ($mobile) {
              min-width: 64px;
              width: 64px;

              img {
                left: 35%;
              }
            }
          }
        }

        .step {
          display: flex;
          justify-content: center;
          align-items: center;

          &1 {
            grid-area: step1;
          }
          &2 {
            grid-area: step2;
          }

          @media all and ($mobile) {
            p {
              font-size: 0.9rem;
              line-height: normal;
            }
          }
        }
      }
    }

    .chart {
      .arrow {
        min-width: 64px;
        height: 5px;
        background-color: $background;
        border-right: 5px solid $background-light;
        position: relative;
        z-index: 0;
        box-sizing: border-box;

        &::after {
          content: '';
          display: block;
          width: 22px;
          height: 20px;
          position: absolute;
          right: -5px;
          top: 50%;
          transform: translateY(-50%);
          border-top: 10px solid $background-light;
          border-bottom: 10px solid $background-light;
          border-left: 22px solid $background;
          box-sizing: border-box;
        }

        &.yes,
        &.no {
          img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background-color: $background-light;
            padding: 2px;
          }
        }

        @media all and (max-width: 750px) {
          min-width: auto;
          min-height: 64px;
          height: auto;
          width: 5px;
          border-right: none;
          border-bottom: 5px solid $background-light;

          &::after {
            width: 20px;
            height: 22px;
            right: auto;
            bottom: -5px;
            top: auto;
            left: 50%;
            transform: translateX(-50%);
            border-bottom: none;
            border-left: 10px solid $background-light;
            border-right: 10px solid $background-light;
            border-top: 22px solid $background;
          }
        }
      }

      .start {
        padding: 8px 24px;
        background-color: rgba($foreground, 0.12);
        border: 1px solid $foreground;
        border-radius: 9999px;
        box-shadow: map-get($elevation, 1);
        z-index: 1;

        p {
          margin: 0;
          padding: 0;
          font-size: 1.125rem;
          font-weight: 600;
          color: $foreground;
          text-align: center;
        }
      }

      .step {
        padding: 8px 16px;
        background-color: $background;
        border-radius: 12px;
        box-shadow: map-get($elevation, 1);
        position: relative;
        z-index: 1;

        p {
          margin: 0;
          padding: 0;
          font-size: 1.125rem;
          font-weight: 400;
          color: $foreground;
          text-align: center;
          word-wrap: break-word;
        }

        &.question {
          margin: 26px 0;
          padding: 8px;
          width: 140px;
          height: 140px;
          transform: rotate(45deg);
          display: flex;
          justify-content: center;
          align-items: center;

          p {
            transform: rotate(-45deg);
            text-align: center;
          }
        }
      }
    }

    .devStates {
      .boxes {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @media all and (max-width: 750px) {
          flex-wrap: wrap;
          justify-content: center;
        }

        @media all and ($mobile) {
          flex-wrap: nowrap;
          flex-direction: column;
          justify-content: flex-start;
          align-items: stretch;
        }

        .box {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          width: calc(33.33% - 16px);
          padding: 24px;
          margin-right: 24px;
          border-radius: 12px;
          background-color: $background;
          box-shadow: map-get($elevation, 1);
          box-sizing: border-box;

          @media all and (max-width: 750px) {
            width: calc(50% - 12px);
            margin-bottom: 24px;

            &:nth-child(even) {
              margin-right: 0;
            }
          }

          @media all and ($mobile) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 24px;
          }

          &.current {
            border: 1px solid $primary;
          }

          &:last-child {
            margin: 0;
          }

          .number {
            text-align: left;
            font-family: 'Montserrat', 'Nunito Sans', sans-serif;
            font-size: 1.25rem;
            font-weight: 900;
            color: $primary;
            margin-right: 16px;
          }

          .text {
            .heading {
              font-size: 1.5rem;
              font-weight: 700;
              text-align: left;
              color: $foreground;
              margin-bottom: 16px;
            }

            .details {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

#target {
  .content {
    .background-text {
      left: 7%;

      @media all and ($mobile) {
        left: -14px;
      }
    }

    .groups {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      @media all and ($mobile) {
        display: block;
      }

      .group {
        width: calc(50% - 24px);

        &:last-child {
          margin-bottom: 0;
        }

        @media all and ($desktop) {
          margin-bottom: 0;
        }

        @media all and ($mobile) {
          width: 100%;
        }

        .description {
          & ::v-deep span {
            color: $primary;
          }
        }
      }
    }
  }
}

#design {
  background-color: $background-light;

  .content {
    .background-text {
      left: 25%;

      @media all and ($mobile) {
        left: -14px;
      }
    }

    .ui {
      .material,
      .responsive {
        display: flex;
        justify-content: space-around;
        align-items: center;

        @media all and ($mobile) {
          flex-direction: column;
          justify-content: flex-start;
        }

        .text {
          width: 25%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          @media all and ($mobile) {
            width: 100%;
            margin-bottom: 48px;
          }

          .icon {
            display: block;
            font-size: 96px;
            width: 96px;
            height: 96px;
            color: $foreground;
            text-align: center;
            margin: 0 auto;
          }

          .heading {
            margin: 0 auto;
            margin-top: 12px;
            width: 144px;
            font-size: 1.125rem;
            font-weight: 700;
            color: $foreground;
            text-align: center;
          }

          .pros {
            margin-top: 48px;
            list-style: none;

            li {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              color: $foreground;
              margin-bottom: 12px;

              &::before {
                content: url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cg%20transform%3D%22translate(-153%20-5385)%22%3E%3Crect%20width%3D%2224%22%20height%3D%2224%22%20transform%3D%22translate(153%205385)%22%20fill%3D%22none%22%2F%3E%3Cpath%20d%3D%22M19%2C13H13v6H11V13H5V11h6V5h2v6h6Z%22%20transform%3D%22translate(153%205385)%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
                display: inline-block;
                margin-right: 8px;
              }
            }
          }
        }

        .components {
          padding: 0 5%;
          max-width: 40%;

          @media all and ($mobile) {
            max-width: 65%;
            width: 65%;
          }

          .inner {
            position: relative;

            picture {
              display: flex;
              justify-content: stretch;
              align-items: center;
              border-radius: 16px;
              box-shadow: map-get($elevation, 1);
              overflow: hidden;

              img {
                width: 100%;
                height: auto;
              }
            }

            & > *:not(picture) {
              position: absolute;

              &.navigationDrawer {
                top: 2.5%;
                right: 95.5%;
                width: calc(24px + 4.5%);

                @media all and ($mobile) {
                  width: calc(16px + 4.5%);
                }
              }

              &.divider {
                top: 16.8%;
                right: 97%;
                width: calc(24px + 3%);

                @media all and ($mobile) {
                  width: calc(16px + 3%);
                }
              }

              &.list {
                top: 37.5%;
                right: 92%;
                width: calc(24px + 8%);

                @media all and ($mobile) {
                  width: calc(16px + 8%);
                }
              }

              &.badge {
                top: 41.5%;
                right: 85.4%;
                width: calc(24px + 14.6%);

                @media all and ($mobile) {
                  width: calc(16px + 14.6%);
                }
              }

              &.appBar {
                top: 4%;
                left: 92%;
                width: calc(24px + 8%);

                @media all and ($mobile) {
                  width: calc(16px + 8%);
                }
              }

              &.iconButton {
                top: 11%;
                left: 88%;
                width: calc(24px + 12%);

                @media all and ($mobile) {
                  width: calc(16px + 12%);
                }
              }

              &.card {
                top: 19%;
                left: 97%;
                width: calc(24px + 3%);

                @media all and ($mobile) {
                  width: calc(16px + 3%);
                }
              }

              &.checkbox {
                top: 65%;
                left: 92.5%;
                width: calc(24px + 7.5%);

                @media all and ($mobile) {
                  width: calc(16px + 7.5%);
                }
              }

              &.fab {
                top: 93.2%;
                left: 95.5%;
                width: calc(24px + 4.5%);

                @media all and ($mobile) {
                  width: calc(16px + 4.5%);
                }
              }
            }
          }
        }
      }

      .responsive {
        margin-top: 96px;

        .text {
          width: 30%;

          @media all and ($mobile) {
            width: 100%;
          }

          .icon {
            font-size: 160px;
            width: 160px;
            height: 132px;
          }

          .heading {
            width: auto;
          }
        }

        .image {
          display: flex;
          justify-content: stretch;
          align-items: center;
          max-width: 60%;

          @media all and ($mobile) {
            max-width: 100%;
            width: 100%;
          }

          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }

    .logo {
      .inner {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media all and ($mobile) {
          flex-direction: column;
          justify-content: flex-start;
          align-items: stretch;
        }

        .image {
          margin-left: -5.5%;
          margin-right: 10%;
          min-width: 45%;
          width: 45%;

          @media all and ($mobile) {
            margin: 0 0 48px 0;
            min-width: 100%;
            width: 100%;
          }
        }

        .text {
          padding: 0 5% 0 0;
          color: $foreground;

          & ::v-deep span {
            color: $primary;
          }

          @media all and ($mobile) {
            padding: 0;
          }
        }
      }
    }

    .colors {
      .inner {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @media all and ($mobile) {
          flex-direction: column;
          justify-content: flex-start;
          align-items: stretch;
        }

        .primary {
          width: 25%;
          margin-right: 25%;

          @media all and ($mobile) {
            width: 75%;
            margin-right: 25%;
            margin-bottom: 32px;
          }

          .heading {
            font-size: 1.125rem;
            font-weight: 900;
            text-align: center;
            margin: 0 0 8px 0;
          }

          .palette {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: stretch;

            .swatch {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 16px;
              position: relative;

              .name {
                text-align: left;
                margin: 0;
              }

              .hex {
                text-align: right;
                margin: 0;
              }

              .marker {
                position: absolute;
                left: 100%;
                top: 50%;
                transform: translateY(-50%);

                @media all and (max-width: 800px) {
                  & ::v-deep .name {
                    white-space: initial;
                  }
                }
              }
            }
          }
        }

        .material {
          width: 50%;

          @media all and ($mobile) {
            width: 100%;
          }

          .heading {
            font-size: 1.125rem;
            font-weight: 900;
            text-align: center;
            margin: 0 0 8px 0;
          }

          .palette {
            column-count: 2;
            column-gap: 24px;

            .swatch {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 16px;
              position: relative;

              .name {
                text-align: left;
                margin: 0;
              }
            }
          }

          .caption {
            font-size: 1rem;
            color: $foreground;
            text-align: center;
            padding: 0 15%;
            margin-top: 24px;
          }
        }
      }
    }

    .typography {
      .inner {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @media all and ($mobile) {
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
        }

        .description {
          padding-top: 16px;
          min-width: 50%;
          max-width: 50%;
          white-space: pre-line;

          @media all and ($mobile) {
            padding-top: 0;
            padding-bottom: 24px;
            min-width: 100%;
            max-width: 100%;
          }
        }

        .fonts {
          margin-top: -32px;
          margin-left: 80px;

          @media all and ($mobile) {
            margin-top: 0;
            margin-left: 0;
          }

          & > * {
            margin-bottom: 24px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

#result {
  position: relative;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    background-color: $background-light;
  }

  &::before {
    top: 17.07%;
    height: 17.06%;

    @media all and ($mobile) {
      top: 12.26%;
      height: 17.93%;
    }
  }

  &::after {
    top: 43.92%;
    height: 32.44%;

    @media all and ($mobile) {
      top: 38.87%;
      height: 47.17%;
    }
  }

  .visitSite {
    background-color: $background !important;

    &:hover {
      background-color: #000000 !important;
    }
  }

  .content {
    .background-text {
      left: 30%;

      @media all and ($mobile) {
        left: -14px;
      }
    }

    .fhg9a {
      h3.heading {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .year {
          font-size: 1rem;
          font-weight: 400;
          color: $text-color;
          margin-left: 12px;
        }
      }

      .gallery {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        .scDesktopHome {
          border-radius: 16px;
          width: 100%;
          margin-right: 0;
        }

        picture {
          display: flex;
          justify-content: stretch;
          align-items: center;
          border-radius: 12px;
          overflow: hidden;
          box-shadow: map-get($elevation, 3);
          width: calc(33.33% - 21.33px);
          margin-bottom: 32px;
          margin-right: 32px;

          &:nth-child(4),
          &:last-child {
            margin-right: 0;
          }

          @media all and ($mobile) {
            width: calc(50% - 8px);
            margin-right: 16px;
            margin-bottom: 16px;

            &:nth-child(4) {
              margin-right: 16px;
            }

            &:nth-child(3),
            &:nth-child(5) {
              margin-right: 0;
            }
          }

          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }

    .fhgOnline {
      h3.heading {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .year {
          font-size: 1rem;
          font-weight: 400;
          color: $text-color;
          margin-left: 12px;
        }
      }

      .gallery {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-columns: min-content;
        grid-template-areas:
          'scWelcome registrationText registrationText'
          'scWelcome scLogin scRegistration'
          'scHome scLogin scRegistration'
          'scHome homeText homeText'
          'scHome scTimetable .'
          'scHomework scTimetable scHelp'
          'scHomework scSettings scHelp'
          'scDesktopHome scDesktopHome scDesktopHome'
          'scDesktopHomework scDesktopHomework scDesktopHomework';
        gap: 32px;
        align-items: flex-start;
        max-width: 100%;
        width: 100%;

        @media all and ($mobile) {
          grid-template-columns: repeat(2, 1fr);
          grid-template-areas:
            'scWelcome .'
            'scWelcome scRegistration'
            'registrationText registrationText'
            'scLogin .'
            'scLogin scHome'
            'homeText homeText'
            '. scTimetable'
            'scHomework scTimetable'
            'scHomework scHelp'
            'scSettings scHelp'
            'scSettings .'
            'scDesktopHome scDesktopHome'
            'scDesktopHomework scDesktopHomework';
          gap: 16px;
        }

        picture {
          display: flex;
          justify-content: stretch;
          align-items: flex-start;
          border-radius: 12px;
          box-shadow: map-get($elevation, 3);
          overflow: hidden;
          width: 100%;
          height: auto;

          img {
            width: 100%;
            height: auto;
          }
        }

        .registration,
        .home {
          .heading {
            font-size: 1.125rem;
            font-weight: 700;
            color: $foreground;
            margin-bottom: 24px;
          }
        }

        .scLogin,
        .scRegistration {
          align-self: center;

          @media all and ($mobile) {
            align-self: flex-start;
          }
        }

        .registration {
          grid-area: registrationText;
        }
        .home {
          grid-area: homeText;
        }
        .scWelcome {
          grid-area: scWelcome;
        }
        .scLogin {
          grid-area: scLogin;
        }
        .scRegistration {
          grid-area: scRegistration;

          @media all and ($mobile) {
            margin-top: 64px;
          }
        }
        .scHome {
          grid-area: scHome;

          margin-top: 64px;
        }
        .scTimetable {
          grid-area: scTimetable;
        }
        .scHelp {
          grid-area: scHelp;

          margin-top: 96px;

          @media all and ($mobile) {
            margin-top: 0;
          }
        }
        .scSettings {
          grid-area: scSettings;
        }
        .scHomework {
          grid-area: scHomework;
        }
        .scDesktopHome {
          grid-area: scDesktopHome;
        }
        .scDesktopHomework {
          grid-area: scDesktopHomework;
        }
      }
    }
  }
}
</style>
