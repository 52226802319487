<template>
  <div
    class="marker"
    :class="{
      'align-left': align == 'left',
      'align-right': align == 'right',
      dark: color == 'dark',
      light: color == 'light',
    }"
  >
    <p class="name" :class="{ inline }"><slot /></p>
    <div class="stroke" :class="{ hideDot }" />
  </div>
</template>

<script>
export default {
  name: 'PortfolioMarker',
  props: {
    align: {
      type: String,
      validator: (s) => s == 'left' || s == 'right',
    },
    hideDot: Boolean,
    inline: Boolean,
    color: {
      type: String,
      default: 'dark',
      validator: (s) => s == 'dark' || s == 'light',
    },
  },
};
</script>

<style lang="scss" scoped>
.marker {
  display: flex;
  justify-items: space-between;
  align-items: center;
  position: relative;

  .name {
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1rem;
    font-weight: 600;
    color: $foreground;
    text-align: right;

    &.inline {
      white-space: nowrap;
    }

    @media all and ($mobile) {
      font-size: 0.8rem;
    }
  }

  &.align-right {
    flex-direction: row-reverse;

    .name {
      text-align: left;
      right: auto;
      left: 100%;
    }

    .stroke {
      margin-left: 0;
      margin-right: 4px;

      &::after {
        right: auto;
        left: 0;
        transform: translate(-50%, -50%);
      }
    }
  }

  .stroke {
    flex-grow: 1;
    min-width: 24px;
    height: 2px;
    background-color: $background;
    margin-left: 4px;
    position: relative;

    @media all and ($mobile) {
      min-width: 16px;
    }

    &.hideDot {
      &::after {
        display: none;
      }
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(50%, -50%);
      width: 12px;
      height: 12px;
      background-color: $foreground;
      border: 2px solid $background;
      border-radius: 50%;
    }
  }

  &.light {
    .stroke {
      background-color: $background-light;

      &::after {
        border-color: $background-light;
      }
    }
  }
}
</style>
